<template>
  <div class="container">
    <header-bar></header-bar>
    <!-- <div class="gl_filter bg_white">
      <el-form :inline="true" class="form">
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="角色名称">
              <el-input placeholder="请输入角色名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select>
                <el-option></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建时间">
              <el-input placeholder="请输入角色名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button class="btn btn_blue" icon="el-icon-search">搜索</el-button>
            <el-button class="btn btn_blue" icon="el-icon-plus" @click="getDialog()">新建角色</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div> -->
    <div class="table">
      <el-table style="width: 100%" :data="tableData">
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column label="角色名称" prop="roleName"></el-table-column>
        <el-table-column label="权限字符" prop="dataScope"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">{{ scope.row.status == 1 ? '正常' : '停用' }}</template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <span class="operate color_blue" @click="getDialog(scope.row.roleId)">编辑</span>
            <!-- <span class="operate color_red" @click="deleteRole(scope.row.roleId)">删除</span> -->
          </template>
        </el-table-column>
      </el-table>
      <pagination v-if="totalCount > 0" :pageSize="formData.pageSize" :total="totalCount" @pageChange="pageVal"></pagination>
    </div>
    <el-dialog
      class="dialog_wrapper"
      :title="dialogTitle"
      v-if="isVisible"
      :visible.sync="isVisible"
      :close-on-click-modal="false"
      top="10vh"
      width="650px"
    >
      <create-role :id="currentId" @changeDialogClose="changeCreateRole"></create-role>
    </el-dialog>
  </div>
</template>
<script>
import headerBar from '@comps/backStageComponent/layout/headerBar/headerBar';
import pagination from '@comps/backStageComponent/pagination';
import createRole from './components/createRole';
import API from '@apis/backStageEndAPI/roleManagement';
export default {
  name: 'roleManagement',
  components: {
    headerBar,
    pagination,
    createRole
  },
  data() {
    return {
      isVisible: false,
      totalCount: 0,
      formData: {
        page: 1,
        pageSize: 10
      },
      tableData: [
        {
          roleName: '普通用户',
          word: 'system_info',
          state: '启用',
          createTime: '2020-02-12 15:20:21'
        }
      ],
      currentId: '',
      dialogTitle: ''
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      this.tableData = await API.getRoleList();
    },
    search() {
      thia.formData.page = 1;
      this.getTableData();
    },
    pageVal(val) {
      this.formData.page = val;
      this.getTableData();
    },
    changeCreateRole(val) {
      if (val) {
        this.getTableData();
      }
      this.currentId = '';
      this.isVisible = !this.isVisible;
    },
    getDialog(id) {
      if (id) {
        this.dialogTitle = '编辑角色';
        this.currentId = id;
      } else {
        this.dialogTitle = '新建角色';
        this.currentId = '';
      }
      this.isVisible = !this.isVisible;
    },
    deleteRole(id) {
      let _this = this;
      this.$confirm('确认要删除此角色吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        API.deleteRole(id).then(res => {
          if (res.code == 0) {
            _this.getTableData();
          } else {
            _this.$message({
              type: 'info',
              message: '已取消'
            });
          }
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.gl_filter {
	margin-bottom: 16px;
	padding: 16px 16px 0;
	border-radius: 12px;
}
</style>
