<template>
  <div class="container">
    <el-form class="form" ref="roleForm" :inline="true" :rules="rules" :model="formData">
      <el-row :gutter="16">
        <el-col :span="12">
          <el-form-item label="角色名称" prop="roleName">
            <el-input
              clearable
              v-model="formData.roleName"
              placeholder="请输入角色名称"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据权限" prop="dataScope">
            <el-select clearable v-model="formData.dataScope">
              <el-option
                v-for="(item, index) in scopeList"
                :key="'数据权限_' + index"
                :value="item.value"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="角色编码" prop="roleKey">
            <el-input
              clearable
              v-model="formData.roleKey"
              placeholder="请输入角色编码"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="formData.status">
              <el-radio :label="'1'">正常</el-radio>
              <el-radio :label="'0'">停用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="角色权限" prop="menuIds">
            <div class="gl_actions">
              <el-button
                size="small"
                :icon="isExpandAll ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                class="btn btn_blue"
                @click="setAllExpand(state)"
                >{{ isExpandAll ? '折叠' : '展开' }}</el-button
              >
              <el-button
                size="small"
                :icon="isCheckedAll ? 'el-icon-close' : 'el-icon-check'"
                class="btn btn_light_green"
                @click="checkAll(isCheckedAll)"
                >{{ isCheckedAll ? '取消全选' : '全选' }}</el-button
              >
            </div>
            <div class="gl_tree_wrapper">
              <el-tree
                ref="tree"
                :data="menuData"
                :props="props"
                :highlight-current="true"
                render-after-expand
                node-key="menuId"
                show-checkbox
                @check="getCheckedNodes"
              >
              </el-tree>
            </div>
          </el-form-item>
        </el-col>
        <el-col class="dialog_submit" :span="24">
          <el-button class="btn btn_blue" @click="submit('roleForm')">提交</el-button>
          <el-button class="btn btn_normal" @click="cancel">取消</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import menuAPI from '@apis/backStageEndAPI/menuManagementAPI';
import API from '@apis/backStageEndAPI/roleManagement';
export default {
  props: {
    id: Number
  },
  data() {
    return {
      formData: {
        roleName: '',
        dataScope: '',
        menuIds: '',
        roleId: '',
        roleKey: '',
        status: '1'
      },
      rules: {
        roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        roleKey: [{ required: true, message: '请输入角色编码', trigger: 'blur' }],
        dataScope: [{ required: true, message: '请选择数据权限', trigger: 'change' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        menuIds: [{ required: true, message: '请选择角色权限', trigger: 'blur' }]
      },
      props: {
        label: 'menuName',
        children: 'children'
      },
      menuData: [],
      scopeList: [
        { value: 'ALL', name: '全部数据权限' },
        { value: 'AREA', name: '区域数据权限' },
        { value: 'DEPT', name: '部门数据权限' },
        { value: 'SELF', name: '个人数据权限' }
      ],
      radio: 0,
      isExpandAll: false,
      state: true,
      isCheckedAll: false
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail(this.id);
    } else {
      this.getMenuList();
    }
  },
  methods: {
    async getMenuList() {
      let res = await menuAPI.getMenuList();
      this.menuData = res;
    },
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([3]);
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
    setAllExpand(state) {
      //展开、折叠全部节点
      this.isExpandAll = state;
      var nodes = this.$refs.tree.store.nodesMap;
      for (var i in nodes) {
        nodes[i].expanded = state;
      }
      this.state = !this.state;
    },
    checkAll(checked) {
      if (checked) {
        //取消全选
        this.$refs.tree.setCheckedKeys([]);
        this.formData.menuIds = this.$refs.tree.getCheckedKeys().join(',');
      } else {
        //全选
        this.$refs.tree.setCheckedNodes(this.menuData);
        this.formData.menuIds = this.$refs.tree.getCheckedKeys().join(',');
      }
      this.isCheckedAll = !this.isCheckedAll;
    },
    cancel() {
      this.$emit('changeDialogClose');
    },
    submit(fromName) {
      this.$refs[fromName].validate((valid) => {
        if (valid) {
          let res;
          if (this.formData.roleId) {
            res = API.editRole(this.formData);
          } else {
            res = API.createRole(this.formData);
          }
          res
            .then((res) => {
              // this.$message.success(res.msg);
              this.$emit('changeDialogClose', true);
            })
            .catch(() => {});
        }
      });
    },
    async getDetail(id) {
      this.loading.show();
      let res = await API.getRole(id);
      let menuRes = await menuAPI.getMenuList();
      this.loading.hide();
      this.formData.roleName = res.roleName;
      this.formData.dataScope = res.dataScope;
      this.formData.menuIds = res.menuIds;
      this.formData.roleId = res.roleId;
      this.formData.roleKey = res.roleKey;
      this.formData.status = res.status;
      this.menuData = menuRes;
      if (res.menuIds) {
        this.formData.menuIds = res.menuIds;
        let checkedNodes = res.menuIds.split(',');
        this.setCheckedNode(checkedNodes, this.menuData);
      } else {
        this.formData.menuIds = '';
      }
    },
    getCheckedNodes(data, options) {
      if (options.checkedKeys.length) {
        this.formData.menuIds = 
          options.halfCheckedKeys.length ? 
            options.halfCheckedKeys.join(',') + ',' + options.checkedKeys.join(',') : 
              options.checkedKeys.join(',')
      } else {
        this.formData.menuIds = '';
      }
    },
    setCheckedNode(ids, menuData) {
      let newArr = [];
      ids.forEach((item) => {
        checked(item, menuData, newArr);
      });
      this.menuInit(newArr);
      function checked(id, data, arr) {
        data.forEach((item) => {
          if (item.menuId == id) {
            newArr.push(item.menuId);
            if (item.children.length != 0) {
              checked(item.menuId, item.children, newArr);
            }
          } else {
            if (item.children.length != 0) {
              checked(id, item.children, newArr);
            }
          }
        });
      }
    },
    menuInit(data) {
      data.shift()//删除父节点，不然会全选
      this.$refs.tree.setCheckedKeys(data);
    }
  }
};
</script>
<style lang="scss" scoped>
.gl_actions {
  margin-bottom: 16px;
}
.gl_tree_wrapper {
  padding: 8px;
  border: 1px solid #dedede;
}
</style>

