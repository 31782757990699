import axios from '@/plugins/http.js';
class menuManagementAPI {
  getMenuList () {
    // 菜单列表
    return axios.get(`/menu-list`);
  }
  addMenu (params) {
    // 新建菜单
    return axios.post(`/menu`, params);
  }
  updateMenu (params) {
    // 编辑菜单
    return axios.put(`/menu`, params);
  }
  getMenuDetail (id) {
    // 菜单详情
    return axios.get(`/menu/${id}`);
  }
  delMenu (id) {
    // 删除菜单
    return axios.delete(`/menu/${id}`);
  }
}
export default new menuManagementAPI();
