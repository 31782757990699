import axios from '@/plugins/http.js';
class roleManagement {
  getRoleList () { // 获取角色列表
    return axios.get(`/role-list`)
  }
  createRole (params) {//新建角色
    return axios.post(`/role`, params)
  }
  editRole (params) {//编辑角色
    return axios.put(`/role`, params)
  }
  deleteRole (id) {//删除角色
    return axios.delete(`/role/${id}`)
  }
  getRole (id) {//获取角色详情
    return axios.get(`/role/${id}`)
  }
}
export default new roleManagement();
